// 点餐管理
import request from '@/utils/util.js'

// ## 点餐列表
// 列表
export function getMealPreorderListAPI(data) {
	return request.get('/meal/preorder/list', data)
}

// 新增、修改
export function saveMealPreorderListAPI(data) {
	return request.post('/meal/preorder/save', data)
}

// 处理
export function setPreorderAPI(data) {
	return request.post('/meal/preorder/set', data)
}



// ## 菜单列表
// 列表
export function getMealListAPI(data) {
	return request.get('/meal/list', data)
}

// 新增、修改
export function saveMealListAPI(data) {
	return request.post('/meal/save', data)
}



// ## 商家管理
// 列表
export function getMerchantListAPI(data) {
	return request.get('/meal/merchant/list', data)
}

// 新增、修改
export function saveMerchantAPI(data) {
	return request.post('/meal/merchant/save', data)
}



// ## 食堂电话配置
// 列表
export function getCanteenTelListAPI(data) {
	return request.get('/config/record_list', data)
}

// 新增、编辑
export function saveCanteenTelAPI(data) {
	return request.post('/config/record_save', data)
}

// 删除
export function delCanteenTelAPI(data) {
	return request.post('/config/record_delete', data)
}